.SearchBox {
  display: flex;
  flex-direction: column;
  background-color: #1f1f1f;
  /* opacity: 0.95; */
  position: fixed;
  top: 5vw;
  width: 33vw;
}

.SearchType {
  color: white;
  margin: 0;
  font-size: 20px;
}

.SearchTitle {
  color: white;
  margin: 0;
  font-size: 17.5px;
}

.SearchSubtitle {
  color: gray;
  margin: 0;
  font-size: 17.5px;
  font-weight: 200;
}

.SearchImage {
  width: 65px;
  height: 65px;
  object-fit: cover;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
  border: 0.1px solid white
}

.SearchBoxSpacer {
  background-color: gray;
  width: 100%;
  /* height: 0.5px; */
}

.SearchBoxText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.SearchBoxItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 0.1px solid gray; */
  height: 80px;
  width: 100%;
}

.SearchItemLeft {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

body {
  /* background-color: #141414; */
  background: linear-gradient(to bottom, #0F0F0F, #241e00, #241e00);

  margin: 0;
}

/* @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  } */

.PrimaryText {
  color: white;
}

#webPlayer-container {
  width: 50vh; /* Set the width of the image to 100% of the container */
  height: 100vh; /* Automatically adjust the height based on the aspect ratio */
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

#SongControlsVMiddle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 20vw;
  padding-right: 20vw;
}

#ControlsSongName {
  font-size: max(1.2vw, 9px);
  color: white;
}

#ControlsArtistName {
  font-size: max(1vw, 7px);
  color: rgba(255, 255, 255, 0.61);
}

#songControlImage{
  object-fit: cover;
  width: 6.5vw;
  height: 6.5vw;
  margin-bottom: 2.5vw;
  margin-left: 2vw;
  border-radius: 1vw;
}

#webPlayerImage {
  width: 50vh; /* Set the width of the image to 100% of the container */
  height: 100vh; /* Automatically adjust the height based on the aspect ratio */
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100vw;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* appearance: none; */
  /* background: #FED700; */
  cursor: pointer;
  width: 10px;
  height: 10px;
  z-index: 3;

  border-radius: 50%;
  background-color: #fed700;
}

input[type="range"] {
  -webkit-appearance: none;
  z-index: 1;
  /* position: relative; */
  height: 2px;
  margin: 0 auto; /* horizontally center */
  width: 90%;
  /* background: white; */
  cursor: pointer;
  /* background-color: #FED700; */
}

#WeplayerSlider{

}

input[type="range"]::-webkit-progress-value {
  height: 2px;
  background-color: #fed700;
}

#infoStack {
  display: flex;
  flex-direction: row;
  z-index: 13;
  align-items: end;
  justify-content: space-between;
  /* height: 36vh;
  width: 100vw; */
}

#infoStackInner {
  display: flex;
  flex-direction: row;
  /* z-index: 1; */
  align-items: center;
}

/* input[type="range"]::-webkit-slider-runnable-track {
    background: #FED700;
} */

#audioStatusIcon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 5vw;
  height: 5vw;
}

#audioCurrentTime {
  width: 10vw;
  padding-left: 2vw;
}

#audioDurationTime {
  /* width: 10vw; */
  position: relative;
  right: 5vw;
}

#timeContainer {
  position: relative;
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

.profile-image {
  /* position: relative; */
  top: 79vh;
  left: 2vw;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #000;
  transition: transform 0.5s ease;
  width: 8vh;
  height: 8vh;
  z-index: 1;
  margin-left: 2vh;
}

.playing {
  animation: rotation 4.5s infinite linear;
}

.paused {
  animation-play-state: paused;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.PrimaryBackground {
  background-color: #fed700;
}

.SecondaryText {
  color: #141414;
}

.SecondaryBackground {
  background-color: #141414;
}

#Soundbar-Logo-Title {
  /* padding-left: 2vw; */
  /* padding-top: 6vw; */
  min-width: 80px;
  /* max-width: 15vw; */
  width: 10vw;
}

.useNavigate-Page-Text {
  padding-top: 1vw;
  /* margin-bottom: 6vw; */
  text-align: center;
  max-width: 60%;
  font-size: max(1.2vw, 12px);
  color: #a7a7a7;
}

#Create-Account-Page {
  /* text-align: center; */
  height: 100vh;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  /* padding-top: 1vw; */
}

#textStack {
  color: white;
  /* z-index: 1; */
  margin-left: 1vh;
}

#Song-Uploaded-Page {
  height: 100vh;
  width: 100vw;
  justify-content: start;
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

#AudioControlStack{
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 19vw;
  margin-right: 19vw;
}

#SongControlsMiddleStack{
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  width: 60vw;
}

.ControlsTime {
  /* width: 10vw; */
  /* position: relative; */
  /* right: 5vw; */
  color: white;
  /* margin  */
}

#volumeIcon{
  width:1.5vw;
  height:1.5vw
}

#volume-slider{
  margin-left: 1vw;
  width: 45%
}

#buttonsStack {
  display: flex;
  justify-content: center;
  z-index: 1;
  height: 8vh;
  width: 10vh;
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  border-radius: 5%;
  margin-right: 2vh;
}

#horizontalStack {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 90px;
  background-color: #4E4E4E;
  width: 100vw;
  /* align-self: end; */
}

#SongControls {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 90px;
  background-color: #11110F;
  width: 100vw;
  z-index: 12;
  align-items: center;
  margin: auto;
  /* align-self: end; */
}

#ControlsTogglePlay{
  margin-left: 2vw;
  margin-right: 2vw;
  width: 2vw;
  height: 2vw;
}

#ControlsInfoStack {
  display: flex;
  flex-direction: row;
  z-index: 13;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.buttonIcon {
  margin-top: 1vh;
}

.buttonStack {
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 5vh;
  height: 9vh;
}

#ControlslikeIcon{
  width: 1.5vw;
  height: 1.5vw;

}

#ControlsSocialsButtons{
  display: flex;
  flex-direction: row;
  align-items: center;

}

#ArtistPageButton{
  width: 3vw;
  height: 3vw;
  /* object-fit: cover; */

}

.SongConrolsButtonStack {
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 9vw;
  margin-right: 2vw;
}

#Home-useNavigate {
  border-radius: 0.5vw;
  color: white;
  /* font-size: min(2vw, 5px); */
  font-size: max(1vw, 12px);
  text-align: center;
  min-width: 10vw;
  max-width: 25vw;
  padding: 1.3vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin-top: 4vw;
  margin-bottom: 2vw;
  margin-left: 1vw;
  margin-right: 1vw;
}

#useNavigate-Button-Stack {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.Info-Page-Text {
  padding-top: 1vw;
  padding-bottom: 1vw;
  vertical-align: middle;
  /* max-width: 30vw; */
  padding-left: 1vw;
  font-size: max(1vw, 10px);
  color: #a7a7a7;
}

.Message-Page-Text {
  padding-top: 1vw;
  padding-bottom: 8vw;
  vertical-align: middle;
  /* max-width: 30vw; */
  padding-left: 1vw;
  font-size: max(1vw, 10px);
  color: #a7a7a7;
}



@media (max-width: 768px) {
  .Info-Page-Text {
    padding-left: 20px;
  }
}

#Soundbar-Sign-Up {
  justify-content: start;
  width: 100vw;
  /* height: 80%; */
  align-items: center;
  display: flex;
  margin-top: 7vw;
  flex-direction: column;
  z-index: 1;
}

#Soundbar-Upload-Box {
  justify-content: center;
  width: 100vw;
  height: 100%;
  align-items: center;
  display: flex;

  flex-direction: column;
  z-index: 1;
}

#Direct-Page-Background {
  justify-content: start;
  align-items: start;
  flex-direction: column;
  position: absolute;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.Page-Created-Background {
  height: 100%;
  width: 100%;
  /* background-size:100px 50px */
  /* background-size: cover; */
}

.Background-Triangle {
  background-size: 100% 50px;
}

#Sign-Up-Header {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 2vw;
}

#Sign-Up-Title {
  font-size: max(2.5vw, 20px);
  margin-bottom: 2vw;
  font-weight: lighter;
}

#Login-Title {
  font-size: max(2.5vw, 20px);
  margin-bottom: 2vw;
  font-weight: 100;
}

#Sign-Up-Direction {
  font-size: max(1.3vw, 10px);
  color: white;
  margin-top: 0.5vw;

  margin-bottom: 2vw;
}

#Login-Direct {
  color: white;
  margin-top: 2vw;
  font-size: max(1.3vw, 10px);
}

@media (max-width: 768px) {
  #Login-Direct {
  }

  #Soundbar-Sign-Up {
    justify-content: start;
    width: 100vw;
    /* height: 80%; */
    align-items: center;
    display: flex;
    padding-top: 20vw;
    flex-direction: column;
    z-index: 1;
  }
}

#Soundbar-Sign-Up-Box {
  justify-content: start;
  /* max-width: 50vw; */
  min-width: 350px;
  width: 55vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 1vw;
}

#useNavigate-Page-Box {
  justify-content: start;
  /* max-width: 50vw; */
  min-width: 300px;
  width: 45vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 1vw;
  /* border-width: 0.01em;  */
  /* border-color: #FED700; */
  /* border-style: outset; */
  background-color: #000000;
}

#Text-Boxes {
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* margin: 0; */
}

.Text-Box {
  width: 45vw;
  /* min-width: 225px; */
  min-width: 280px;
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  /* text-justify:auto; */
  /* margin-left: 5vw;
    margin-right: 5vw; */
  background-color: #262626;
  border-radius: 0.4vw;
  margin-bottom: 1vw;
  min-height: 30px;
}

@media (max-width: 768px) {
  .Text-Box {
  }
}

#Sign-Up-Create-Button {
  border-radius: 0.4vw;

  font-size: max(1.8vw, 10px);
  text-align: center;
  min-width: 10vw;
  max-width: 25vw;
  padding: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
  color: white;
  border-color: #fed700;
  margin-top: 3vw;
  margin-bottom: 4vw;
}

#Apply-Button {
  border-radius: 10px;
  color: white;
  padding: 1vh;
  padding-left: 3vw;
  padding-right: 3vw;

  padding: 0;
  border: none;

  height: 70px;

  font-size: max(1.5vw, 12px);
  margin-top: 4vw;
  margin-bottom: 4vw;

  width: 45vw;
  /* min-width: 225px; */
  min-width: 280px;
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  /* text-justify:auto; */
  /* margin-left: 5vw;
    margin-right: 5vw; */
  background-color: #262626;
  border-radius: 0.4vw;
  margin-bottom: 1vw;
  min-height: 30px;
}

.SubmitButtonLogin {
  width: 46vw;
  background-color: #fed700;
  color: white;
  height: 60px;
  border-radius: 0.4vw;
  align-items: center;
  justify-content: center;
}

.SubmitButtonLoginText {
  font-size: 25px;
  margin: 0;
}

@media (max-width: 768px) {
}

.SharePageShadow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  z-index: 99999;
}

.shareComponent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 400px;

  background-color: #1a1a1a;
  opacity: 1;
  border-radius: 15px;
}

.ImageAndSongName {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.shareText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.contentImage {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.shareOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.shareItem {
  color: white;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
  font-weight: 400;
}

.shareTitleOne {
  color: white;
  margin: 0;
}

.shareTitleTwo {
  color: white;
  margin: 0;
}

.ShareItemFull {
  width: 100%;
}

.shareFullLine {
  /* background-color: #2a2a2a; */
  background-color: #3a3a3a;
  height: 0.1px;
  width: 400px;
}

.sharePartLine {
  background-color: white;
  height: 0.1px;
  width: 250px;
}

.externalShareOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.externalShareOptionsItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.externalShareOptionsImage {
  margin-top: 10px;
  height: 50px;
  width: 50px;
  margin-bottom: 7.5px;
  border-radius: 100px;
}

.externalShareText {
  margin: 0;
  margin-bottom: 10px;
  color: white;
  font-size: 14px;
  font-weight: 300;
}

.shareItemFull {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shareItemIcon {
  width: 30px;
  height: 30px;
}

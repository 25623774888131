#Soundbar-Affiliate-Apply {
    
    min-width: 300px;
    width: 45vw;
    margin: auto;

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#Underline{
    min-width: 100px;
    width: 15vw;
}

#Join-Affiliate-Title{
    color: white;
    font-size: max(3vw, 20px);
    margin-left: 2vw;

}

#Join-Affiliate-Direction {
    font-size: max(2.5vw, 20px);
    margin-left: 2vw;

}

@media (max-width: 768px){
    #Join-Affiliate-Title{
        margin-left: 4vw;
    
    }

    #Join-Affiliate-Direction {
        margin-left: 4vw;
    
    }
}



#Join-Affiliate-Header{
    margin-bottom: 4vw;
    margin-top: 8vw;
}

#Apply-Button {
    border-radius: 10px;
    color: white;
    padding: 1vh;
    padding-left: 3vw;
    padding-right: 3vw;
    
    font-size: max(1.5vw, 12px);
    margin-top: 4vw;
    margin-bottom: 4vw;

}

#Upload-Button {
    font-size: max(1.5vw, 15px);
    border-radius: 10px;
    color: white;
    padding: 0.5vh;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-right: 0,5vw;
}

#Music-Box {
    min-height: 120px;
    min-width: 280px;
    -webkit-justify-content: center;
    width: 45vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* padding-top: 1vw; */
    /* padding-left: 1vw; */
    background-color: #262626;
    border-radius: 0.4vw;
    /* margin-bottom: 1vw; */
    color: #A7A7A7;
    height: 10vw;
    /* padding-left: 0.5vw; */
    /* padding-right: 0.5vw; */

}

/* #Upload-Box {
    min-height: 120px;
    min-width: 280px;
    -webkit-justify-content: center;
    width: 45vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    background-color: #262626;
    border-radius: 0.4vw;
    margin-bottom: 1vw;
    color: #A7A7A7;
    height: 10vw;
} */

.UploadButton {
    
    background-color: #FED700;
    border-radius: 0.4vw;
    color: white;
    margin-top: 1vw;
    margin-bottom: 1vw;
    padding: 0.5vw;
    padding-left: 2vw;
    padding-right: 2vw;

}

@media (max-width: 768px) {
    .Text-Box {
      padding-left: 20px;
    }

    #Music-Box{
        padding-left: 20px;
        /* padding-right: 10px; */
    }

    #Upload-Box {
        
        padding-left: 10px;
        padding-right: 10px;
        
    }
    
  }

#Music-Box-Content{
    /* min-height: 60px;
    height: 5vw; */
    /* justify-content: start; */
    align-items: center;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 1vw; */
    font-size: max(1vw, 10px);

}

#Upload-Song-Button {
    padding: 0.4vw;
    padding-left: 3vw;
    padding-right: 3vw;
    font-size: max(0.8vw, 7px);
    margin-top: 1vw;
    border-radius: 0.4vw;
    color: white;

    
}

#Sign-Up-Page-Button{
    border-radius: 10px;
    color: white;
    padding: 0.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    margin-right: 2vw;
    
    font-size: max(1.5vw, 15px);
}


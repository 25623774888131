.player_container
{
    width: 50%;
    padding: 1rem;
    border: 1px solid rgb(141, 141, 141);
    border-radius: 10px;
    color: rgb(218, 218, 218);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    position: fixed;
    padding: 1rem 0;
    color: rgb(202, 202, 202);
    
}

.title{
    font-size: inherit;
}

.navigation{
    width: 100%;
}

.navigation_wrapper
{
    min-width: 100%;
    background-color: rgba(119, 119, 119, 0.781);
    height: 5px;
    border-radius: 30px;
    cursor: pointer;

}

.seek_bar{
    width: 0;
    height: 100%;
    background-color: yellow;
    border-radius: 30px;
}

.controls{
    font-size: inherit;
    display: flex;
    align-items: center;    
}

.btn_action
{
    font-size: 2rem;
    margin: 0 1rem;
    cursor: pointer;

}

.btn_action:hover{
    color: white;
}

.pp
{
    font-size: 4rem;
    
}

.ProfileLayout {
  display: flex;
  flex-direction: row;
  /* margin-left: 26vw; */
}

#ProfileTop {
  width: 77vw;
  margin-top: calc(5vw + 2.5%);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2.5%;
  /* width: 100%; */
}

#profilePicture {
  width: 15vw;
  height: 15vw;

  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
}

#editProfilePicture {
  width: 20vw;
  height: 20vw;

  margin-left: 2vw;

  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
}

#Username {
  font-size: 30px;
  color: white;
  text-align: center;
}

#Fullname {
  font-size: 25px;
  color: #707070;
  text-align: center;
}

.ProfileMainTopDetails {
  display: flex;
  align-items: start;
  flex-direction: column;

  height: 8vw;
}

#ProfileMainDetails {
  display: flex;
  height: 15vw;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2vw;
}

#bio {
  font-size: max(1.4vw, 12px);
  color: white;
  font-weight: bold;
}

@media (max-width: 768px) {
  #Username {
    text-align: center;
    padding-bottom: 1.5vw;
  }
}

#profileInfo {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#ProfileDetails {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 15vw;
}

.profileButton {
  width: 18vw;
  height: 50px;
  /* margin: 2vw; */

  font-size: 20px;
  font-weight: 300;

  margin-top: 15px;
  background-color: #222222;
  /* border-radius: 0.5vw; */
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

#uploadButton {
  width: 135px;
  height: 40px;

  background-color: #fed700;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.UploadProfile {
  color: white;
  font-size: 22.5px;
  font-weight: 400;
}

#profileButtons {
  width: 44vw;
  height: 10vw;
  margin: 2vw;
  margin-top: 1vw;
  justify-content: center;
  display: flex;
  align-items: center;
}

.infoSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 60px;
}

.infoValue {
  font-size: 25px;
  color: white;
}

.infoTitle {
  font-size: 30px;
  color: darkgray;
  font-weight: 300;
}

.lineSeperator {
  width: 90vw;
  height: 0.5px;
  background-color: gray;
}

#ProfilePage {
  width: 77vw;
}

#songOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 50px;
  width: 95%;
  margin-left: 2.5%;
  /* width: 100%; */
  justify-content: space-between;
}

#underlines {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 3px;
}

.optionHighlight {
  width: 9vw;
  margin-right: 3vw;
  height: 1.5px;
}

#EditProfileLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editTitle {
  color: white;
}

.SubmitProfileEdit {
  width: 47vw;
  background-color: #fed700;
  color: white;
  height: 60px;
  border-radius: 0.4vw;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
  margin-top: 2vw;
}

#EditProfileBox {
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 12vw;
}

.Edit-Box {
  width: 45vw;
  height: 4vw;
  min-width: 225px;
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  background-color: #262626;
  border-radius: 0.4vw;
  margin-bottom: 1vw;
  padding-left: 2vw;
  min-height: 30px;
  margin-right: 2vw;
}

.EditBio-Box {
  width: 45vw;
  height: 8vw;
  min-width: 225px;
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  background-color: #262626;
  border-radius: 0.4vw;
  margin-bottom: 1vw;
  min-height: 30px;
  margin-right: 2vw;
  padding-left: 2vw;
}

.Message-Page-Text {
  padding-top: 1vw;
  height: 8vw;
  vertical-align: middle;
  max-width: 30vw;
  padding-left: 1vw;
  font-size: max(1vw, 10px);
  color: #a7a7a7;
}

#toggleType {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 30px;
  /* background-color: #262626; */
  border-radius: 1vw;
  color: white;
}

#toggleTypes {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.toggleItemTextBox {
  width: 12vw;
}

.toggleItem {
}

#sortButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: max(3vw, 15px);
  color: white;
  margin-left: 30%;
}

#sortTitle {
  margin-right: 2vw;
}

#sortIcon {
  width: 3vw;
  height: 3vw;
}

#listensIcon {
  width: 2vw;
  height: 2vw;
  min-width: 15px;
  min-height: 15px;

  margin: 0.5vw;
}

#listenCount {
  color: white;
  font-size: max(1.2vw, 10px);
}

#songImageDetails {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 8vw;
  height: 8vw;
  min-width: 60px;
  min-height: 60px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 85%);
  border-radius: 50%;
}

#songImage {
  background-size: cover;
  width: 12vw;
  height: 12vw;
  min-width: 90px;
  min-height: 90px;

  display: flex;
  align-items: center;
  border-radius: 1vw;
}

#songItems {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

#songTextInfo {
  margin-left: 2vw;
  margin-right: 2vw;
  width: 15vw;
  min-width: 80px;
}

#songName {
  font-size: max(2.3vw, 14px);
  color: white;
}

#by {
  color: white;
  font-size: max(1.3vw, 9px);
}

#songArtist {
  color: gray;
  font-size: max(1.3vw, 9px);
}

#songControlButtons {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 2vw;
}

.ProfileSongCell {
  display: flex;
  align-items: center;
  flex-direction: row;

  width: 95%;
  margin-left: 2.5%;
  margin-top: 15px;
  justify-content: space-between;
  background-color: rgba(60, 60, 60, 0.5);
}

#SongCellMainDetails {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#songItemIcon {
  width: 1.5vw;
  height: 1.5vw;
  min-width: 15px;
  min-height: 15px;
}

.songItemDetail {
  margin: 1.7vw;
  margin-top: 0vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.songItemValue {
  font-size: max(2vw, 14px);
  color: white;
  font-weight: 300;
}

.ExploreContent {
  margin-top: 5vw;
  padding-left: 2vw;
  padding-right: 2vw;

  /* width: 100%; */
}

/* FIX??? */
.HomePage {
  display: flex;

  width: 100vw;
}

.ExploreTopContent {
  display: flex;
  flex-direction: row;
  
  /* padding-left: 2vw; */
  /* width: 100vw; */
}

.ExploreRow {
}

.ExploreSingleSongRow {
}

.ExploreDoubleSongRow {
}

.ExploreTrendingArtistsRow {
}

.RowTitleBig {
  font-size: 45px;
  font-weight: lighter;
  color: white;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

.RowTitleMedium {
  font-size: 35px;
  font-weight: lighter;
  color: white;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

.RowSubtitle {
  font-size: 20px;
  font-weight: lighter;
  color: white;
  margin: 0;
}

.songExploreCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 270px;
  height: 100px;
  background-color: rgba(60, 60, 60, 0.5);
  border-radius: 3px;
}

.HomeSongLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixedSidebar {
  position: fixed;
}

.SongRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: 270px 270px 270px 270px;
  grid-template-rows: 100px 100px;

  grid-gap: 18px;

  margin-top: 10px;
}

.artistRowExplore {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.artistExploreCell {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: rgba(60, 60, 60, 0.171);
  border-radius: 20px;
}

.artistExploreImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
}

.artistExploreUsername {
  margin: 0;
  color: white;
}

.artistExploreName {
  margin: 0;
  color: white;
}

#Affiliate-Direct-Header {
  border-radius: 10px;
  color: black;
  font-size: max(5vw, 25px);
}

#Affiliate-Direct-Text {
  border-radius: 10px;
  color: white;
  font-size: max(2vw, 12px);
  padding-top: 0.5vw;
}

#Affiliate-Content {
  padding-top: 2vh;
  width: 100vw;
}

#Join-Affiliate-Page {
  vertical-align: bottom;

  /* height: 60vh; */
}

#Sign-Up-Affiliate {
  border-radius: 0.5vw;
  color: white;
  /* font-size: min(2vw, 5px); */
  font-size: max(1.5vw, 20px);
  text-align: center;
  min-width: 10vw;
  max-width: 25vw;
  padding: 1.5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  margin-top: 3vw;
}

.Bulletin {
  min-width: 5px;
  width: 0.6vw;
  margin-right: 1vw;
}

#Login-Button {
  border-radius: 10px;
  color: white;
  padding: 1vh;
  padding-left: 2vw;
  padding-right: 2vw;
  /* min-width: 40px;
max-width: 20vw; */
  font-size: max(2vw, 12px);
}

#Affiliate-Perks {
  padding-left: 5vw;
  padding-top: 2vw;
}

.Perk-Line {
  font-size: max(1.2vw, 11px);
  padding-bottom: 1.5vw;

  color: white;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

@media (max-width: 768px) {
  .Perk-Line {
    padding-bottom: 6vw;
  }

  #Affiliate-Perks {
    padding-left: 7vw;
    padding-top: 4vw;
  }
  #Sign-Up-Affiliate {
    padding: 2vw;
    padding-left: 6vw;
    padding-right: 6vw;
  }
}

#Affiliate-Direct-Box {
  /* min-height: 150px; */
  /* max-height: 40vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  /* padding-left: 2vw; */
  padding-top: 2vw;
  padding-bottom: 2vw;

  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

@media (max-width: 768px) {
  #Quick-Links-Inner {
    flex-direction: column;
    align-items: center;
  }

  #Join-Affiliate-Page {
    /* margin-bottom: 1vw; */

    /* height: 60vh; */
  }

  #Affiliate-Direct-Box {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }
}

.AffiliatePage {
  margin-top: 5vw;
}

.AffiliateBoxAffiliate {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 175px;
  object-fit: cover;
  background-image: url("../Svg/AffiliateImage.jpeg");
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: center;
}

.AffiliateBoxAffiliateShadow {
  display: flex;
  justify-content: center;

  flex-direction: column;

  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  margin: 0;
}

.AffiliateBoxATitle {
  color: white;
  font-size: 40px;
  font-weight: lighter;
  opacity: 1;
  margin: 0;
  margin-bottom: 15px;
  margin-left: 20px;
}
.ApplyAffiliateAButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  border: 0.75px solid white;
  margin: 0;
  border-radius: 5px;
  margin-left: 20px;
}

.AffiliateTitle {
  font-size: 40px;
  color: white;
  font-weight: 200;
  text-align: center;
  margin: 0;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.AffiliateDesc {
  font-size: 25px;
  color: rgba(255, 255, 255, 0.685);
  font-weight: lighter;
  text-align: center;
  margin: 0;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.AffiliateContent {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-bottom: 50px;
  margin-left: 20px;
}

.AffiliateRowThree {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.AffiliateRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 40px;
}

.AffiliateItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24%;
  height: 150px;
  background-color: #362e00;
  margin-top: 20px;
  padding: 1vw;
  margin-bottom: 15px;
  border-radius: 5px;
}

.AffiliateText {
  margin-top: 1vw;
  color: white;
  text-align: center;
  font-weight: 150;
  font-size: 20px;
}

.AffiliateImg {
  width: 2vw;
  height: 2vw;
}

.OurAffiliates {
  margin-left: 2vw;
  margin-bottom: 5vw;
}

.OurAffiliatesTitle {
  font-size: 40px;
  color: white;
  font-weight: 200;
  margin: 0;
  /* margin-left: 20px; */
  margin-top: 20px;
  margin-bottom: 20px;
}

#ArtistInfo {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  height: 100%;
  width: 100%;
  border-radius: 1vw;
  justify-content: end;
}

.OurAffiliatesContent {
  display: flex;
  justify-content: space-evenly;
  /* margin-left: 2vw; */
  flex-direction: row;
  width: 98%;
}

.OurAffiliatesContent > *:not(:first-child) {
  margin-left: 1vw;
  /* margin-right: auto; */
}

.OurAffiliatesContent > *:last-child {
  margin-right: 2vw;
}

.OurAffiliatesContent > * {
  flex: 1 1 auto;
  flex-basis: 0;
}

.ArtistAccount {
  display: flex;
  width: 200px;
  height: 400px;
  border-radius: 1vw;
  /* background-color: #2c2c2c; */
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  /* margin-right: 20px; */

  /* border-radius: 5px; */
  /* padding: ; */
}

.ArtistCardImage {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 200px;
}

.AffiliateUsername {
  font-size: 15px;
  color: white;
  margin: 0;
}

.AffiliateName {
  font-size: 20px;
  /* font-size: 30px; */
  color: white;
  font-weight: 400;
  margin: 0;
}

.AffiliateBiography {
  font-size: 11px;
  color: white;
  font-weight: 400;
  width: 85%;
}

.AffiliteArtistInfo {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  border-radius: 0 0 1vw 1vw;
  justify-content: space-evenly;
  align-items: center;
}

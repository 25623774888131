body {
  background-color: #0f0f0f;
  margin: 0;
  overflow-x: hidden;
}

html {
  background-color: #0f0f0f;
  overflow-x: hidden;
  overflow-y: scroll;
}

div {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
}

.PrimaryText {
  color: white;
}

.PrimaryBackground {
  background-color: #fed700;
}

.SecondaryText {
  color: #141414;
}

.SecondaryBackground {
  background-color: #141414;
}

#HomePage {
  height: 100vh;
}

.YellowBottomHomePage {
  background-color: #fed700;
  width: 100vw;
  height: 150px;
}

#Home-Controls {
  max-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: start;
}

#Soundbar-Logo {
  padding-left: 2vw;
  /* padding-top: 1vw; */
  /* min-width: 80px; */
  /* max-width: 15vw; */
  width: 15vw;
  z-index: 5;
}

#Soundbar-Logo-Mobile {
  padding-left: 2vw;
  /* padding-top: 1vw; */
  /* min-width: 80px; */
  /* max-width: 15vw; */
  height: 30px;
  z-index: 5;
}

#AppStore-Logo {
  height: 4vw;
  margin: 0;
  object-fit: fill;
}

#AppStore-Logo-Mobile {
  height: 40px;
  margin: 0;
  object-fit: fill;
}

.appStoreLink {
  text-decoration: none;
}

.LeftComponents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  margin-right: 15px;
}

.LeftComponentsMobile {
  width: 100%;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#Search-Icon {
  width: 2vw;
  height: 2vw;
  /* margin-left: 2vw; */
  margin-right: 2vw;
  /* left: max(22vw, 100px); */
  /* position: absolute; */
}

#Search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33vw;
  border: none;
  color: white;
}

#Logo-Box {
  /* justify-content: start; */
  /* position: absolute; */
  /* left: 0; */

  width: 20vw;
  align-items: center;
  display: flex;
  flex-direction: row;
  /* left: 0%; */
  /* padding-top: 2.5vw; */
}

#Top-Controls-Box {
  /* top: 0; */
  /* right: 0; */
  height: 5vw;
  position: absolute;

  justify-content: space-between;
  /* justify-content: end; */
  align-items: center;
  width: 100vw;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);

  background-color: #0f0f0f;
  display: flex;
  flex-direction: row;
  z-index: 99999;
}

#Top-Controls-Box-Mobile {
  /* top: 0; */
  /* right: 0; */
  height: 50px;

  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  /* justify-content: end; */
  align-items: center;
  width: 100vw;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);

  background-color: #0f0f0f;
  display: flex;
  flex-direction: row;
  z-index: 99999;
}

#menuItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}
#Sign-Up-Button {
  text-align: center;
  border-radius: 10px;
  color: white;
  padding: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 20vw;
  /* height: 2.5vw; */
  /* max-width: 20vw; */
  font-size: 2vw;
}

#Menu-Button {
  padding-left: 2vw;
  padding-right: 1vw;
  width: 3vw;
  height: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: center;

  /* min-width: 15px;
  max-width: 100px; */
  /* margin-top: 0.5vh; */

  /* margin-bottom: 1vh; */
}
#Menu-Button-Mobile {
  padding-left: 2vw;
  padding-right: 1vw;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  /* min-width: 15px;
  max-width: 100px; */
  /* margin-top: 0.5vh; */

  /* margin-bottom: 1vh; */
}

.MenuButton:hover {
  color: #fed700;
}

.MenuButtonFrame:hover {
  color: #fed700;
}
.MenuButtonFrame {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.yellow-line {
  position: fixed;
  top: 5vw;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fed700;
  z-index: 9999;
}

.yellow-line-mobile {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fed700;
  z-index: 9999;
}

.red-line {
  width: 100%;
  height: 2px;
  background-color: #49462c;
  z-index: 9999;
}

.menu-line {
  width: 0.5px;
  height: 5vw;
  background-color: #707070;
  z-index: 99999;
}

#menuShadow {
  width: 100vw;
  height: 100vh;
  background-color: rgba(20, 18, 6, 0.6);
}

.signUpOptions {
  font-size: max(1.8vw, 14px);
  /* width: 15vw; */
  /* margin-left: 0.5vw; */
  margin-left: 3vw;

  font-weight: 340;
  color: white;
}

#Profile-Options {
  /* vertical-align:bottom; */
  /* align-items: end; */
  display: flex;
  position: relative;
  flex-direction: column;
  /* text-align: end; */
  margin-top: 6vw;
  padding-top: 2vw;
  /* height: 20vw; */
  /* width: 26vw; */
  min-width: 200px;
  background-color: rgba(31, 31, 31, 0.9);
  /* bottom: 0; */
}

.signTitle {
  color: white;
}

.signText {
  color: white;
}

#ProfilePicture img {
  width: 4vw;
  height: 4vw;
  margin-right: 2vw;
  object-fit: cover;
  border-radius: 50%;

  /* -ms-interpolation-mode: bicubic; Scaled images look a bit better in IE now */
}

.menuIcon {
  width: 3vw;
  height: 3vw;
  margin-right: 1vw;
  margin-left: 3vw;
  object-fit: fill;

  /* -ms-interpolation-mode: bicubic; Scaled images look a bit better in IE now */
}

.menuIconArrow {
  width: 3vw;
  height: 3vw;
  /* margin-right: 3vw;
  margin-left: 3vw; */
  object-fit: fill;

  /* -ms-interpolation-mode: bicubic; Scaled images look a bit better in IE now */
}

#menuImages {
  margin-left: 2vw;
  margin-right: 1vw;
  width: 3vw;
  height: 3vw;
  border-radius: 2vw;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#menuImagesMobile {
  margin-left: 2vw;
  margin-right: 1vw;
  width: 30px;
  height: 30px;
  border-radius: 2vw;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#Search-Textbox {
  width: 22vw;
  /* min-width: 225px; */
  /* min-width: 280px; */
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  /* text-justify:auto; */
  /* margin-left: 5vw;
  margin-right: 5vw; */
  background-color: #262626;
  border-radius: 0.4vw;
  color: white;
  /* margin-bottom: 1vw; */
  height: 2.7vw;
  border: 1px solid black;
  border-radius: 1.5vw;
  padding-left: 1.5vw;
  border-color: rgba(104, 104, 104, 0.9);
}

#Logout-Button {
  /* position: absolute; */
  /* margin-top: 55vh; */
  font-size: max(2.5vw, 14px);
  width: 8vw;
  /* right: 0; */
  /* margin-left: 15vw; */
  font-weight: 340;
  color: rgb(255, 0, 0);
  /* height: 100%; */
  /* padding: 1vw; */
  /* border-radius: 1vw; */
}

#Menu-Button-Box {
  /* vertical-align:bottom; */
  /* align-content: end; */
  display: flex;
  position: relative;
  flex-direction: column;
  top: 0;
  right: 0;
  position: fixed;
  /* height: 100vh; */
  width: 100vw;
  z-index: 11;
}

#Menu-Controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  background-color: rgba(38, 38, 38);
  border-radius: 0px 0px 0px 4vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 2vw;
  /* width: 39vw; */
  /* right: 1vw; */
}

#Menu-Controls-NoUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-top: 1vw;
  padding-bottom: 1vw;
  /* padding-left: 1vw; */
  /* width: 39vw; */
  height: 4vw;
  /* right: 1vw; */
}

#User-Greeting {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 30vw;
  height: 4vw;
  /* margin-right: 2vw; */
}

#Manage-User {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 30vw;
  height: 6vw;
}
#Error {
  padding-top: 1vw;
  font-size: max(2vw, 12px);
}

#Menu-Shadow {
  top: 6vw;
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 100vh;
  width: 40vw;
  max-width: 30%;
  min-width: 180px;
  z-index: 1;
  right: 0;
}

#Menu-Options {
  /* vertical-align:bottom; */
  /* align-items: end; */
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* text-align: end; */
  width: 300px;
  margin-top: 7vw;

  height: 100vh;
  /* width: 26vw; */
  background-color: rgba(31, 31, 31, 1);
  /* bottom: 0; */
}

.SpacerDiv {
  background-color: white;
  width: 90%;
  height: 0.5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  #Menu-Options {
    /* width: 43vw; */
    /* height: 100vh; */
    /* padding-left: 0v w; */
  }
}

#Menu-Box {
  position: absolute;
  background-color: rgba(31, 31, 31, 1);
  /* height: 100%; */
  align-content: end;
  display: flex;
  flex-direction: column;
  right: 0;
  /* padding-left: 20vh; */
}

#Profile-Box {
  position: absolute;
  /* background-color: rgba(38, 38, 38, 0.45); */
  /* height: 100%; */
  align-content: end;
  display: flex;
  flex-direction: column;
  right: 0;
  /* padding-left: 20vh; */
  width: 30%;
}

#MenuDivider {
  width: 22vw;
  margin-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  min-height: 2px;
}

.MenuButton {
  font-size: max(2.5vw, 14px);
  font-size: 25px;
  /* width: 15vw; */
  /* margin-left: 0.5vw; */
  font-weight: 340;
  color: white;
}

@media (max-width: 768px) {
  #MenuDivider {
    padding-top: 4vw;
    padding-bottom: 4vw;
    width: 28vw;
  }
  #Menu-Box {
    /* padding-left: 8vh; */
    padding-top: 5vw;
    /* padding-top: 12vh; */
  }
}

#Motto {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-left: 2vw;
  width: max-content;
  /* padding-top: 8vw; */
  /* position: absolute; */
  z-index: 4;
  margin-top: 8vw;
  padding-bottom: 15vw;
  font-family: Arial, Helvetica, sans-serif;
}

#MottoHome {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-left: 6vw;
  width: max-content;
  /* padding-top: 8vw; */
  position: absolute;
  z-index: 4;
  margin-top: -4.5vw;
  /* padding-bottom: 15vw; */
  font-family: Arial, Helvetica, sans-serif;
}

#MottoPhonesBox {
  right: 12vw;

  position: absolute;
  width: 50vw;
  height: 47vw;

  justify-content: end;
}

#MotorPhones {
  position: absolute;
  bottom: 5vw;
  display: inline-flex;
  flex-direction: row;
  justify-content: end;
}

@media (max-width: 768px) {
  #MottoPhonesBox {
    right: 12vw;
  }

  #MotorPhones {
    bottom: 0vw;
  }
}

#TitlePhone1 {
  height: 100%;
  max-width: 62vw;
}

#TitlePhone2 {
  height: 60%;

  max-width: 22vw;
  margin-top: 4vw;
}

#DiscoverMusic {
  color: white;

  font-size: max(4vw, 22px);
}

/* #MadeForYou {
  position: relative;

  margin: 8vw;
  margin-left: -3vw;
  margin-bottom: -5vw;
  font-size: max(4vw, 15px);
  background-color: #FED700;
  width: 30vw;
  border-radius: 4vw;
  text-align: end;
  padding: 2vw 0;
  padding-right: 2vw;
  z-index: 2;
} */

#MadeForYou {
  font-size: max(3vw, 15px);
  font-weight: 500;
}

.Phone-background {
  position: absolute;
  background-color: #fed700;
  width: 103vw;
  border-radius: 4vw;
  height: 65vw;
  z-index: 0;

  margin-top: 15vw;
  margin-bottom: -10vw;
}

.Text-background {
  position: absolute;
  border-radius: 4vw;
  align-items: center;
  width: 40vw;
  height: 30vw;
  margin-right: 2vw;
  background-color: #141414;
}

#Leftbackground {
  /* transform: rotate(180deg); */
  margin-left: -40vw;
  /* left: 0; */
}

#Rightbackground {
  right: 0;
  margin-right: -20vw;
}

#Beta-Direct-Box {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: nowrap;
  padding-left: 2vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

#Sign-Up-Beta {
  border-radius: 0.5vw;
  color: white;
  font-size: max(2.5vw, 10px);
  text-align: center;
  min-width: 10vw;
  max-width: 25vw;
  padding: 2vw;
  filter: drop-shadow(0.3vh 0.2vh 0.4vw black);
}

#Beta-Direct-Text {
  border-radius: 10px;
  color: black;
  font-size: max(2vw, 10px);
  padding-top: 1.5vw;
  max-width: 40vw;
}

.Phone {
  position: relative;
  z-index: 3;
  max-width: 25vw;
  margin-top: 1vw;
}

.Phone-Mobile {
  z-index: 3;
  width: 50vw;
}

.Icon {
  margin: 1vw;
  margin-left: 5vw;
  max-width: 4vw;
}

#App-Rows {
  /* display: flex;
  flex-direction: column;
  z-index: 3;
  align-items: center; */
  /* margin-bottom: 10vw; */
}

.App-Row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: relative;
  /* align-items: start; */
  /* margin-top: 10vw; */
}

.LeftPhone {
  margin-right: 50vw;
  align-items: start;
  margin-bottom: -5vw;
}

.RightPhone {
  margin-left: 40vw;
  align-items: end;
  margin-bottom: 5vw;
}

.App-Page-Info {
  margin: 10vw;
  margin-top: 5vw;
  position: absolute;
  border-radius: 4vw;
  /* align-items: center; */
  /* width: 60vw; */
  /* height: 25vw; */
  z-index: 0;
  /* margin-right: 2vw; */
  background-color: #141414;
  padding-top: 4vw;
  padding-bottom: 4vw;
  margin-bottom: 8.5vw;
}

.App-Page-Text {
  /* max-width: 40vw; */
  font-size: max(3vw, 12px);
  /* color: #A7A7A7; */
  color: white;
  text-align: start;
  /* margin: auto;  */
  margin-left: 5vw;
  margin-right: 5vw;
}

#App-Page-Text-Left {
}

#RightbackgroundText {
  /* margin-right: 40vw; */
  left: -55vw;
  padding-right: 8vw;
}

#LeftbackgroundText {
  /* margin-right: 0vw; */
  right: -44vw;
  padding-left: 10vw;
}

#Soundbar-Updates-Box {
  justify-content: center;
  width: 100vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 6vw;
  padding-bottom: 6vw;
}

#Soundbar-Updates-Title {
  padding-left: 2vw;
  padding-right: 2vw;
  font-size: max(4vw, 25px);
}

#Soundbar-Updates-Info {
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vw;
  margin-bottom: 3vw;
  color: white;
  font-size: max(2.5vw, 15px);
  text-align: center;
}

#Email-Submit-Box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#Email {
  color: white;
  flex-wrap: nowrap;
  padding-left: 3vw;
  padding-right: 20vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: max(1.5vw, 10px);
}

#Button {
  color: white;
  flex-wrap: nowrap;
  padding-left: 3vw;
  padding-right: 20vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: max(1.5vw, 10px);
}

#UpdatesEmail {
  width: 20vw;
  color: white;
  /* flex-wrap: nowrap; */
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: max(1.5vw, 10px);
}

#Submit {
  color: white;
  background-color: #ffdf2e;
  flex-wrap: nowrap;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  text-align: center;
  font-size: max(1.5vw, 10px);
}

#Quick-Links {
  padding: 3vw 0% 1vw 0%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 100%;
  /* vertical-align:bottom; */
  width: 100%;
}

#Quick-Links-Inner {
  max-width: 1200px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* padding-right: 5vw; */

  justify-content: end;
}

@media (max-width: 768px) {
  #Quick-Links-Inner {
    flex-direction: column;
    align-items: center;
    padding-right: 0vw;
  }

  #Quick-Links {
    padding: 10vw 0% 50px 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: end;
    width: 100%;
    height: 100%;
  }
}

#User-Gretting-Welcome {
  color: #fed700;
  margin-right: 1vw;
}

#User-Email {
  color: white;
  font-size: max(2vw, 10px);
}

#User-Gretting {
  font-size: max(2vw, 10px);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  #User-Gretting {
    align-content: space-between;

    /* flex-direction: column; */
    margin-right: 1vw;
  }
}

.Link-Title {
  font-size: max(1.5vw, 10px);
  padding-bottom: 0.5vw;
}

.Link-Button {
  padding-bottom: 1vw;
}

#LinksDivider {
  width: 22vw;
  min-width: 150px;
  padding-top: 1vw;
  padding-bottom: 1vw;
  min-height: 2px;
  display: none;
}

@media (max-width: 768px) {
  .Link-Button {
    text-align: center;
    padding-bottom: 1.5vw;
  }

  .Link-Button-Copyright {
    text-align: center;
    padding-bottom: 1.5vw;
  }

  .Link-Title {
    text-align: center;
  }
  #LinksDivider {
    margin-bottom: max(10vw, 10px);
    margin-top: max(5vw, 5px);
  }

  #LinksDivider {
    display: block;
  }
}

#Quick-Links-Buttons {
  font-size: max(1vw, 15px);
}

#Quick-Links-SoundbarLogo {
  /* min-width: 100px; */
  /* width: 15vw; */
}

#Business-Info {
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: end;
  flex-direction: column;
  font-size: max(1vw, 12px);
}

#Quick-Links-Socials {
  width: 5vw;
  min-width: 40px;
  margin: 1vw;
}

@media (max-width: 768px) {
  #Business-Info {
    align-items: center;
  }
}
/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.Link-Button-Copyright {
  padding-bottom: 1vw;
  color: darkgray;
}

.FooterBackgroundBox {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 210px;
  /* background-color: #1e1e1e; */

  align-items: start;
  justify-content: space-between;
}

.LeftInfo {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 170px;
  margin-left: 30px;

  margin-top: 15px;
}
.LeftTitle {
  color: white;
  font-size: 35px;
  font-weight: 200;
  margin: 0;
  margin-bottom: 5px;
}

.Address {
  color: white;
  font-size: 15px;
  font-weight: 200;
  margin: 0;
  margin-bottom: 5px;
}

.EmailLayout {
  margin-top: 15px;
  display: flex;
  margin-bottom: 5px;
}
.EmailLayoutBottom {
  display: flex;
}

.EmailPartOne {
  color: white;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
}

.EmailPartTwo {
  font-size: 15px;
  color: white;
  font-weight: 200;
  margin: 0;
  margin-left: 5px;
}

.SocialIconLayout {
  margin-top: 15px;
}

.SocialIcon {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.MiddleInfo {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-top: 15px;
}

.MiddleTitle {
  color: white;
  font-size: 35px;
  font-weight: 200;
  margin: 0;
  margin-bottom: 6px;
}

.LinkLayout {
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-bottom: 12px;
}

.LinkGroupTwo {
  margin-left: 30px;
}

.LinksPages {
  display: flex;
  flex-direction: row;
}

.LinkArrow {
  width: 10px;
  height: 10px;
}

.Link {
  color: white;
  font-size: 18px;
  font-weight: 200;
  margin: 0;
  margin-left: 7px;
}

.InfoLink {
  color: white;
  font-size: 18px;
  font-weight: 200;
  margin: 0;
}

.RightInfo {
  margin-right: 30px;
  margin-top: 15px;
}

.RightTitle {
  color: white;
  font-size: 35px;
  font-weight: 200;
  margin: 0;
  margin-bottom: 3px;
}

.RightSubtitle {
  color: white;
  font-size: 12px;
  font-weight: 200;
  margin: 0;
  margin-bottom: 3px;
}

.EmailUpdatesLayout {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.EmailBox {
  width: 300px;
  border-radius: 5px;
}

.EmailText {
  color: white;
  margin-left: 20px;
  background-color: transparent;
  text-decoration: none;
  border: 0;
}

.EmailSubmitBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: #fed700;
  margin-left: 10px;
  border-radius: 5px;
  height: 40px;
}

.EmailSubmitText {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.EmailUnderline {
  height: 1px;
  background-color: white;
  width: 100%;
}

.NotificationSpacer {
  width: 100%;
  height: 0.4px;
  background-color: gray;
  z-index: 9999;
}

.NotificationsSidebar {
  height: 100vh;
  width: 500px;
  background-color: rgba(31, 31, 31, 1);
  margin-top: 5vw;
}

.NotificationsDisplay {
  height: 100vh;

  display: flex;

  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* text-align: end; */

  /* width: 26vw; */
  background-color: rgba(31, 31, 31, 1);
  z-index: 9999;
}

.NotificationCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70px;
  margin-top: 15px;
}

.NotificationText {
  margin-left: 15px;
}

.NotificationContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
}

.NotificationContentLeft {
  display: flex;
  align-items: center;
}

.NotifProfileImage {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  margin-left: 15px;
}

.NotifContentImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
  border: 0.75px solid white;
}

.notifMessage {
  color: white;
  font-size: 15px;
  margin: 0;
}

.notifMessageContent {
  color: white;
  font-size: 15px;
  font-weight: 400;
  color: gray;
  margin: 0;
}

.notifTimeStamp {
  font-weight: 200;
  color: gray;
  margin: 0;
}

.followButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 35px;
  background-color: #fed700;
  border-radius: 5px;
  margin-right: 15px;
}

.followText {
  color: white;
}

.HomeTopContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding-left: 2vw; */
  /* margin-left: 4vw; */
}

.SoundbarAffiliates {
  padding-left: 2vw;
  width: 100%;
}

.HomePageInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* width: 77vw; */

  height: 50vh;
  margin-bottom: 20px;
  margin-top: 6vw;
}

.HomePageInfoRow > * {
  flex: 1 1 auto;
  flex-basis: 0;
}

.TopRowHome {
}

.HomePageInfoRowTwo {
  flex-direction: row;
  justify-content: space-evenly;
  height: 50vh;
}

.AffiliateBox {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  height: 175px;
  object-fit: cover;
  background-image: url("../Svg/AffiliateImage.jpeg");
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: center;
}

.AffiliateBoxTitle {
  color: white;
  font-size: 40px;
  font-weight: lighter;
  opacity: 1;
  margin: 0;
  margin-bottom: 15px;
}

.AffiliateBoxSubitle {
  color: #fed700;
  font-size: 40px;
  font-weight: lighter;
}

.AffiliateBoxShadow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  margin: 0;
}

.ApplyAffiliateButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  border: 0.75px solid white;
  margin: 0;
  border-radius: 5px;
}

.ApplyAffiliateText {
  font-size: 30px;
  font-weight: lighter;
  color: white;
}

.InfoTitle {
  font-size: 50px;
  font-weight: lighter;
  color: white;
  margin: 0;
  margin-bottom: 10px;
  white-space: nowrap;
}

.HomePage {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.HomePageContent {
  display: flex;
  flex-direction: column;
  width: 77vw;
}

.TopHits {
  padding-left: 2vw;
  height: 30vh;
  margin-right: 1vw;
}

.InfoOne {
  height: 30vh;
}

#ExplorePage-Content {
  margin-top: -100px;
  width: 80%;
  margin-left: 12vw;
  margin-top: 6vw;
  z-index: -5;
}

.HomeSongs {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  height: 40vh;
  min-height: 40vh;
  max-height: 40vh;
}

.HomeColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40vh;
}

.HomeSong {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 18vw;
  min-width: 260px;
  height: 100px;
  background-color: rgba(60, 60, 60, 0.171);
  border-radius: 3px;
}

.HomeSongLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.songDetials {
  margin-left: 10px;
}

.songNameTopHits {
  font-size: 15px;
  color: white;
}
.artistNameTopHits {
  font-size: 15px;
  color: gray;
}

.playIconTopHits {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.topHitsSongInfo {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 70%;
  width: 70%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 70%);
  border-radius: 50%;
}

.listensIconText {
  width: 30%;
  height: 30%;
}

.listenCountText {
  color: white;
}

.HomeAffiliateBoxRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow-x: scroll;
  /* width: 2000px; */
  width: 98%;
  /* white-space: wrap; */
}

#HomePageHStack {
  display: flex;
  flex-direction: row;
}

.HomeAffiliateBoxRow > *:not(:first-child) {
  margin-left: 1vw;
  /* margin-right: auto; */
}

.HomeAffiliateBoxRow > *:last-child {
  margin-right: 2vw;
}

.HomeAffiliateBoxRow > * {
  flex: 1 1 auto;
  flex-basis: 0;
}

.HomeAffiliateBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 270px;
  height: 300px;
  background-color: #2c2c2c;
  margin-left: 20px;
  border-radius: 3px;
}

.HomeAffiliateImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid white;
  border-radius: 100px;
}

.HomeAffiliateUsername {
  font-size: 20px;
  color: white;
}

.HomeAffiliateName {
  font-size: 20px;
  color: gray;
}

.InfoTitleAffiliates {
  font-size: 50px;
  font-weight: lighter;
  color: white;
  margin: 0;
  /* margin-left: 20px; */
  margin-bottom: 10px;
}

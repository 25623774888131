.AffiliateApplicationPage {
  margin-top: 100px;
  height: 100vh;
}

.AffiliateApplicationPageMobile {
  margin-top: 50px;
  height: 100vh;
}

body #creatorElement {
  /* SurveyJS Creator V1 */
  --primary-color: #7ff07f;
  --secondary-color: #1ab394;
  --primary-text-color: #4a4a4a;
  --secondary-text-color: #a7a7a7;
  --inverted-text-color: #ffffff;
  --primary-hover-color: #6fe06f;
  --selection-border-color: #1ab394;
  --primary-icon-color: #3d4d5d;
  --primary-bg-color: #f8f8f8;
  --secondary-bg-color: #f4f4f4;
  --primary-border-color: #e7eaec;
  --secondary-border-color: #ddd;
  --error-color: #ed5565;
}
body {
  /* SurveyJS Creator V2 */
  --primary: #fed700;
  --primary-light: rgba(26, 183, 250, 0.1);
  --foreground: #ededed;
  --primary-foreground: #ffffff;
  --secondary: #fed700;
  --background: #555555;
  --background-dim: rgba(0, 0, 0, 0);
  --background-dim-light: #4d4d4d;
}

.AffiliateApplicationCompletePage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  height: 95vh;
}

.AffiliateApplicationSuccessBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #272727;
  width: 50vw;
  height: 50vh;
  box-shadow: 5.7px 11.4px 11.4px hsl(0deg 0% 0% / 0.32);
}

.soundbarLogoCircle {
  width: 100px;
  height: 100px;
}

.AffiliateCongrats {
  color: white;
}

.AffiliateDetailsEmailed {
  color: white;
}

.referralCodeIs {
  color: white;
}

.homeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fed700;
  width: 300px;
  height: 100px;
}

.homeButtonText {
  color: white;
}

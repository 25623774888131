#Sidebar {
  /* height: 3000px; */

  min-width: 23vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-top: 5vw;
  /* background-color: #1f1f1f; */
  background-color: #1a1a1a;
  z-index: 10;
  /* position: absolute; */
}

.SidebarAllignContent {
}

.sidebarSection {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.sidebarTitle {
  color: white;
  font-size: 28px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 300;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
}

.ArtistCellSidebar {
  display: flex;
  margin-bottom: 12px;
  justify-content: center;
  align-items: center;
}

#artistImage {
  width: 60px;
  height: 60px;
  border-color: white;
  border-radius: 50%;
  object-fit: cover;
}

.username {
  font-size: 15px;
  color: white;
  margin-left: 15px;
  margin-bottom: 3px;
}

.fullName {
  font-size: 15px;
  color: gray;
  margin-left: 15px;
}

.songCell {
  margin-bottom: 12px;
  height: 70px;
  width: 300px;
  background-color: #1a1a1a;
}

.songNameSidebar {
  font-size: 15px;
  color: white;
}
.artistNameSidebar {
  font-size: 15px;
  color: gray;
}

#songListens {
  color: #707070;
}

#songImageDetailsSidebar {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 30px;
  width: 30px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 85%);
  border-radius: 50%;
}

.HomeSongSidebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  height: 100px;
  width: 300px;
  background-color: #2c2c2c45;
  margin-right: 10px;
  border-radius: 0.5vw;
}

#songImageSidebar {
  background-size: cover;
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  border-radius: 3px;
}

.SidebarSongCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  height: 100px;
  width: 300px;
}

.SidebarSongImageBox {
}

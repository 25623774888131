#Upload-Box {
    /* min-height: 120px; */
    /* min-width: 280px; */
    -webkit-justify-content: center;
    width: 45vw;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    background-color: #262626;
    border-radius: 0.4vw;
    margin-bottom: 1vw;
    color: #A7A7A7;
    height: 4vw;
}

#Music-Box-Content {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 1vw;
    padding-right: 2vw;
    /* padding-bottom: 1vw; */
    font-size: max(1vw, 10px);
}

#Soundbar-Upload-Page {
    min-width: 300px;
    width: 45vw;
    margin-left: 2vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.UploadText {
    padding-top: 1vw;
    padding-bottom: 1vw;
    vertical-align: middle;
    /* max-width: 30vw; */
    padding-left: 2vw;
    font-size: max(1vw, 10px);
    color: #a7a7a7;
  }
  
.UploadTextBox{
    width: 44vw;
    /* min-width: 225px; */
    min-width: 280px;
    justify-content: center;
    align-items: start;
    display: flex;
    flex-direction: column;
    background-color: #262626;
    border-radius: 0.4vw;
    margin-bottom: 1vw;
    min-height: 30px;
}

.UploadSelectBox{
    width: 46vw;
    /* min-width: 225px; */
    min-width: 280px;
    justify-content: center;
    align-items: start;
    display: flex;
    flex-direction: column;
    background-color: #262626;
    border-radius: 0.4vw;
    margin-bottom: 1vw;
    min-height: 30px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    vertical-align: middle;
    /* max-width: 30vw; */
    padding-left: 0.5vw;
}

#Upload-container {

    width: 37vh;
    /* height: 55vh; */
    object-fit: cover;
    position: absolute;
    top: 0;
    /* left: 0; */
    right: 0;
    bottom: 0;
    margin: auto;
    margin-right: 5vw;
    /* max-width: 100vw; */
    display: flex;
    flex-direction: column;
    justify-content: end;
}

#uploadTimeContainer {
    position: relative;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 45vw;
}

#uploadHashtagText{
    color: white;
    width: 44vw;
}

#uploadCurrentTime {
    width: 10vw;
    /* padding-left: 2vw; */
}

#uploadCliptimeText{
    color: white;
    width: 44vw;
    margin-top: 2vw;
}

#Upload-Button {
    border-radius: 10px;
    color: white;
    padding: 1vh;
    padding-left: 3vw;
    padding-right: 3vw;
    font-size: max(1.5vw, 12px);
    margin-top: 4vw;
    margin-bottom: 4vw;
    width: 20vw;
    background-color: #fed700;
}

#uploadButtonsStack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    /* height: 8vh; */
    width: 6vw;
    background-color: rgba(0, 0, 0, 0.2);
    align-items: center;
    border-radius: 5%;
    margin-right: 2vh;
}

#uploadHorizontalStack {
    /* position: fixed; */
    /* top: 0; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: 90px; */
    /* background-color: #4E4E4E; */
    width: 100%;
    /* align-self: end; */
}

#uploadInfoStack {
    display: flex;
    flex-direction: row;
    z-index: 1;
    align-items: end;
    justify-content: space-between;
    height: 36vh;
    width: 100%;
    margin-bottom: 2vw;
}

#uploadPlayerImage {
    width: 100%;
    height: 52vw;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    /* max-width: 100vw; */
}

#uploadRange {
    -webkit-appearance: none;
    z-index: 1;
    /* position: relative; */
    height: 2px;
    margin: 0 auto;
    width: 100%;
    /* background: white; */
    cursor: pointer;
    /* background-color: #FED700; */
}

#uploadSongName{
    font-size: max(1.1vw, 8px);
    color: white;
}

.profile-image {
    /* position: relative; */
    top: 79vh;
    left: 2vw;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #000;
    transition: transform 0.5s ease;
    width: 8vh;
    height: 8vh;
    z-index: 1;
    margin-left: 2vh;
}

.selectMulti {
    appearance: none;
    -webkit-appearance: none; /* For Safari */
  
    /* -webkit-align-items: center;
    -webkit-box-align: center; */
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    /* min-height: 38px; */
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    /* background-color: hsl(0, 0%, 100%); */
    /* border-color: hsl(0, 0%, 80%); */
    border-radius: 4px;
    border-style: solid;
    border-width: 0px;
    box-sizing: border-box;
}
.HomeMobileCarousel {
  margin-top: 60px;
  width: 95%;
  height: 200px;
  background-color: green;
  border-radius: 10px;
  border-radius: 1vw;
  z-index: 1;
  border: solid 0.5px rgb(254, 215, 0);
}

#MobileHomePage {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PhoneWireframeSetup {
  margin-top: 20px;
}

.LeftYellowBackgroundMobile {
  /* padding: 10px; 
  padding-top: -100px;  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LeftPhoneImageMobile {
  width: 95vw;
}

.RightPhoneImageMobile {
    width: 95vw;
  }

.LeftYellowBackgroundInnerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RightYellowBackgroundInnerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LeftYellowBackgroundInnerMobile::before {
  content: "";
  position: absolute;
  top: 250px;
  left: -70px;
  right: 0;
  bottom: 0;
  background-color: #fed700; /* Set your desired background color */
  transform: translate(-10%, -50%) scale(1); /* Adjust the scale value to control the colored area size */
  z-index: -1;
}

.RightYellowBackgroundInnerMobile::before {
  content: "";
  position: absolute;
  top: 250px;
  left: 70px;
  right: -70px;
  bottom: 0;
  background-color: #fed700; /* Set your desired background color */
  transform: translate(-10%, -50%) scale(1); /* Adjust the scale value to control the colored area size */
  z-index: -1;
}
